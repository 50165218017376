import { BaseResponse } from 'src/models/api/response/BaseResponse';

import { apiPost } from '../api-utils';

/**
 *
 * @param email The email address of the staff to be invited
 * @returns
 */
export const inviteNewStaff = (email: string) =>
  apiPost<BaseResponse>('send-invite-staff', {
    email,
  });
