import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useErrorHandler from 'src/hooks/useErrorHandler';

import { shopOwnerAPI } from 'src/utils/api';

import notification from 'src/Components/Common/notification';
import Spinner from 'src/Components/Common/Spinner/Spinner';

function InviteNewStaff() {
  const { t } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState('');

  const [isSendingInvitation, setSendingInvitation] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isSendingInvitation) {
      return false;
    }

    setEmailError('');
    setSendingInvitation(true);

    shopOwnerAPI.inviteNewStaff(email)
      .then((res) => {
        // setEmail('');
        notification(t('notifications.invitationSent'));
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e);
        // }
      })
      .finally(() => {
        setSendingInvitation(false);
      });
  };

  return (
    <div className="mt-4 p-3 p-lg-4 invite-box">
      <h2 className="text-white">{t('shopPrivate.staff.inviteStaff')}</h2>
      <form
        className="p-3 bg-white d-flex flex-column flex-lg-row"
        onSubmit={handleSubmit}
      >
        <div className="error-field invite__field">
          <input
            className="px-4 py-0 border invite__item"
            name="email"
            type="text"
            placeholder={t('generalFields.email.label')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && (
            <>
              <span className="error-ico">
                <i className="fas fa-info-circle" />
              </span>
              <span className="error-text">{emailError}</span>
            </>
          )}
        </div>
        <button
          className="mt-3 mt-lg-0 invite__btn"
          disabled={!email}
          type="submit"
        >
          {isSendingInvitation ? <Spinner /> : t('shopPrivate.staff.invite')}
        </button>
      </form>
    </div>
  );
}

export default InviteNewStaff;
