import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { extractShopStaffList, fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { DataTableStatus, TableHeaderCell } from '../../../Common/DataTable';


import RequestedStaff from './RequestedStaff';
import StaffItem from './StaffItem';
import InviteNewStaff from './InviteNewStaff';


class StaffManagement extends Component {

  componentDidMount = () => {
    this.props.fetchShopStaff({ id: this.props.auth.user.id, fields: 'staff_requests' });
  };

  render() {
    const {
      isFetchingShopStaffDataList,
      staffDataList,
      requestedStaffList,

      auth,
      t,
    } = this.props;

    const routes = [{ title: t('shopPrivate.shopLeftMenu.staff'), path: window.location.pathname }];

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-users" aria-hidden="true" />
          {t('shopPrivate.shopLeftMenu.staff')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">

          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.staff.requestedStaff')}</h2>
          </div>
          <div className="mb-5 main-container__content">
            <div className="tableWrap">
              <table className="adminTable">
                <colgroup>
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '15%' }} />
                  <col />
                  <col style={{ width: 200 }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <TableHeaderCell>{t('generalFields.humanFirstName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.humanLastName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('staff.nickName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.email.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('general.label.action')}</TableHeaderCell>
                  </tr>
                </thead>
                {!isFetchingShopStaffDataList && (
                  <tbody>
                    {requestedStaffList.map((staff, index) => (
                      <RequestedStaff
                        key={index}
                        indexNum={index + 1}
                        currentShopId={auth.user.id}
                        staff={staff}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <DataTableStatus
                isLoading={isFetchingShopStaffDataList}
                itemCount={requestedStaffList.length}
              />
            </div>
          </div>

          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.staff.staffList')}</h2>
          </div>
          <div className="main-container__content">
            <div className="tableWrap">
              <table className="adminTable">
                <colgroup>
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '12%' }} />
                  <col />
                  <col style={{ width: 150 }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <TableHeaderCell>{t('generalFields.humanFirstName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.humanLastName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('staff.nickName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.staff.phoneNumber')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.email.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('general.label.action')}</TableHeaderCell>
                  </tr>
                </thead>
                {!isFetchingShopStaffDataList && (
                  <tbody>
                    {staffDataList.map((staffData, index) => (
                      <StaffItem
                        key={index}
                        indexNum={index + 1}
                        currentShopId={auth.user.id}
                        staffData={staffData}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <DataTableStatus
                isLoading={isFetchingShopStaffDataList}
                itemCount={staffDataList.length}
              />
            </div>
          </div>

          <InviteNewStaff />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const auth = state.auth;

  const shopId = auth.user.id;

  const {
    isFetchingShopStaffDataList,
    staffDataList,
    requestedStaffList,
  } = extractShopStaffList(state, shopId);

  return {
    auth,

    isFetchingShopStaffDataList,
    staffDataList,
    requestedStaffList,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    {
      fetchShopStaff,
    }
  )(StaffManagement)
);
